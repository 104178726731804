/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import storageAvailable from '../../utilities/storageAvailable';
import userLogout from '../thunks/user/logout';
import userLogin from '../thunks/user/login';
import sysConfig from '../../config';
import i18n from '../../i18n';

const isLSAvailable = () => storageAvailable('localStorage');

const initialState = {
  isLSAvailable: isLSAvailable(),
  isLSAllowed: false,
  datetimeFormatLong: i18n.t('datetimeFormatLong') !== 'datetimeFormatLong'
    ? i18n.t('datetimeFormatLong')
    : 'YYYY-MM-DD HH:mm:ss',
  is24h: i18n.t('is24h') !== '1',
  jwtWS: false,
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    checkIsLSAllowed(state) {
      state.isLSAvailable = isLSAvailable();

      if (state.isLSAvailable) {
        const lsAgreeDateISO = localStorage.getItem('lsAgreeDate');
        if (!lsAgreeDateISO) {
          state.isLSAllowed = false;
        } else {
          const lsAgreeDate = moment(lsAgreeDateISO)
            .clone();
          if (!lsAgreeDate.isValid) {
            state.isLSAllowed = false;
          } else {
            state.isLSAllowed = lsAgreeDate
              .isAfter(moment(sysConfig.REACT_APP_LOCALSTORAGE_LAST_POLICY_CHANGE_ISO8601));
          }
        }
      } else {
        state.isLSAllowed = false;
      }
    },
    setDatetimeProps(state, action) {
      //deprecated, initialized as initialState
      // if (action?.payload?.datetimeFormatLong) {
      //   state.datetimeFormatLong = action.payload.datetimeFormatLong;
      // }
      // if (typeof action?.payload?.is24h !== 'undefined') {
      //   state.is24h = action.payload.is24h;
      // }
    },
  },
  extraReducers: {
    [userLogout.fulfilled]: (state) => {
      state.jwtWS = false;
    },
    [userLogout.rejected]: (state) => {
      state.jwtWS = false;
    },
    [userLogin.fulfilled]: (state, action) => {
      state.id = action?.payload?.jwtWS || false;
    },
    [userLogin.rejected]: (state) => {
      state.jwtWS = false;
    },
  },
});

export const {
  checkIsLSAllowed,
  setDatetimeProps,
} = app.actions;

export default app;
