import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiFetch } from '../../../utilities/appFetch';
import locationRoutes from '../../../components/location/locationRoutes';

const update = createAsyncThunk(
  'thunk/location/read',
  async (arg, thunkAPI) => {
    const response = await apiFetch(locationRoutes.api.generate.read(arg?.id), {
      method: 'GET',
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export default update;
