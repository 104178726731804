/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import permissionReadAll from '../thunks/permission/readAll';
import permissionUpdate from '../thunks/permission/update';
import permissionRead from '../thunks/permission/read';
import permissionDelete from '../thunks/permission/delete';

const initialState = {
  permissions: {},
  permissionIds: [],
};

const permission = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setUser(state, action) {
      state.id = action.payload;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
  },
  extraReducers: {
    [permissionReadAll.fulfilled]: (state, action) => {
      const actionPermissions = Array.isArray(action?.payload) ? action.payload : [];
      const statePermissions = {};
      const statePermissionIds = [];
      actionPermissions.forEach((actionPermission) => {
        statePermissions[actionPermission.id] = actionPermission;
        statePermissionIds.push(actionPermission.id);
      });
      state.permissions = statePermissions;
      state.permissionIds = statePermissionIds;
    },
    [permissionReadAll.rejected]: (state) => {
      state.permissions = [];
    },
    [permissionUpdate.fulfilled]: (state, action) => {
      if (!state.permissionIds.includes(action.payload.id)) {
        state.permissionIds.push(action.payload.id);
      }
      state.permissions[action.payload.id] = action.payload;
    },
    [permissionUpdate.rejected]: (state, action) => {
      console.log(action);
    },
    [permissionRead.fulfilled]: (state, action) => {
      if (!state.permissionIds.includes(action.payload.id)) {
        state.permissionIds.push(action.payload.id);
      }
      state.permissions[action.payload.id] = action.payload;
    },
    [permissionRead.rejected]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.permissions[id];
        state.permissionIds = state.permissionIds.filter((pId) => pId !== id);
      }
    },
    [permissionDelete.fulfilled]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.permissions[id];
        state.permissionIds = state.permissionIds.filter((pId) => pId !== id);
      }
    },
    [permissionDelete.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const {
  setUser,
  setPermissions,
} = permission.actions;

export default permission;
