import { combineReducers } from 'redux';
import app from '../slices/app';
import appUser from '../slices/appUser';
import layout from '../slices/layout';
import loading from '../slices/loading';
import menu from '../slices/menu';
import user from '../slices/user';
import permissionGroup from '../slices/permissionGroup';
import permission from '../slices/permission';
import client from '../slices/client';
import location from '../slices/location';
import alarm from '../slices/alarm';
import alarmSequence from '../slices/alarmSequence';
import geoGroup from '../slices/geoGroup';
import snackbar from '../slices/snackbar';

export default combineReducers({
  app: app.reducer,
  appUser: appUser.reducer,
  layout: layout.reducer,
  loading: loading.reducer,
  menu: menu.reducer,
  user: user.reducer,
  permissionGroup: permissionGroup.reducer,
  permission: permission.reducer,
  alarm: alarm.reducer,
  alarmSequence: alarmSequence.reducer,
  geoGroup: geoGroup.reducer,
  location: location.reducer,
  client: client.reducer,
  snackbar: snackbar.reducer,
});
