const alarmRoutes = {
  readAll: '/alarm',
  update: '/alarm/update/:id',
  create: '/alarm/update/new',
  read: '/alarm/:id',
  responder: {
    readAll: '/alarm/responder',
    update: '/alarm/responder/update/:id',
    generate: {
      update: (id) => `/alarm/responder/update/${id}`,
    },
    api: {
      readAll: '/alarm/responder',
    },
  },
  operator: {
    readAll: '/alarm/operator',
    update: '/alarm/operator/:id',
    generate: {
      read: (id) => `/alarm/operator/${id}`,
      update: (id) => `/alarm/operator/${id}`,
    },
    api: {
      readAll: '/alarm/operator',
    },
  },
  operatorNew: {
    readAll: '/alarm/operator/new',
    api: {
      readAll: '/alarm/operator/new',
    },
  },
  operatorPlanned: {
    readAll: '/alarm/operator/planned',
    api: {
      readAll: '/alarm/operator/planned',
    },
  },
  operatorCurrent: {
    readAll: '/alarm/operator/current',
    api: {
      readAll: '/alarm/operator/current',
    },
  },
  operatorClosed: {
    readAll: '/alarm/operator/closed',
    api: {
      readAll: '/alarm/operator/closed',
    },
  },
  management: {
    readAll: '/alarm/management',
    update: '/alarm/management/update/:id',
    read: '/alarm/management/read/:id',
    generate: {
      read: (id) => `/alarm/management/read/${id}`,
      update: (id) => `/alarm/management/update/${id}`,
    },
  },
  generate: {
    update: (id) => `/alarm/update/${id}`,
    read: (id) => `/alarm/${id}`,
  },
  api: {
    readAll: '/alarm',
    generate: {
      update: (id) => {
        if (id) {
          return `/alarm/${id}`;
        }
        return '/alarm';
      },
      patch: (id) => `/alarm/${id}`,
      read: (id) => `/alarm/${id}`,
      remove: (id) => `/alarm/${id}`,
    },
  },
};

export default alarmRoutes;
