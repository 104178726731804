/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import geoGroupReadAll from '../thunks/geoGroup/readAll';
import geoGroupUpdate from '../thunks/geoGroup/update';
import geoGroupRead from '../thunks/geoGroup/read';
import geoGroupDelete from '../thunks/geoGroup/delete';

const initialState = {
  geoGroups: {},
  geoGroupIds: [],
};

const geoGroup = createSlice({
  name: 'geoGroup',
  initialState,
  reducers: {
    setUser(state, action) {
      state.id = action.payload;
    },
    setPermissions(state, action) {
      state.geoGroups = action.payload;
    },
  },
  extraReducers: {
    [geoGroupReadAll.fulfilled]: (state, action) => {
      const actionPermissions = Array.isArray(action?.payload) ? action.payload : [];
      const statePermissions = {};
      const statePermissionIds = [];
      actionPermissions.forEach((actionPermission) => {
        statePermissions[actionPermission.id] = actionPermission;
        statePermissionIds.push(actionPermission.id);
      });
      state.geoGroups = statePermissions;
      state.geoGroupIds = statePermissionIds;
    },
    [geoGroupReadAll.rejected]: (state) => {
      state.geoGroups = [];
    },
    [geoGroupUpdate.fulfilled]: (state, action) => {
      if (!state.geoGroupIds.includes(action.payload.id)) {
        state.geoGroupIds.push(action.payload.id);
      }
      state.geoGroups[action.payload.id] = action.payload;
    },
    [geoGroupUpdate.rejected]: (state, action) => {
      console.log(action);
    },
    [geoGroupRead.fulfilled]: (state, action) => {
      if (!state.geoGroupIds.includes(action.payload.id)) {
        state.geoGroupIds.push(action.payload.id);
      }
      state.geoGroups[action.payload.id] = action.payload;
    },
    [geoGroupRead.rejected]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.geoGroups[id];
        state.geoGroupIds = state.geoGroupIds.filter((pId) => pId !== id);
      }
    },
    [geoGroupDelete.fulfilled]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.geoGroups[id];
        state.geoGroupIds = state.geoGroupIds.filter((pId) => pId !== id);
      }
    },
    [geoGroupDelete.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const {
  setUser,
  setPermissions,
} = geoGroup.actions;

export default geoGroup;
