/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import userAuthenticate from '../thunks/user/authenticate';
import userLogout from '../thunks/user/logout';
import userLogin from '../thunks/user/login';
import userLoginWithUUID from '../thunks/user/loginWithUUID';
import socketIO from '../../socketIO/socketIO';

const initialState = {
  id: 0,
  email: '',
  tokenId: 0,
  permissions: ['login'],
};

const appUser = createSlice({
  name: 'appUser',
  initialState,
  reducers: {
    setUser(state, action) {
      state.id = action.payload;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
  },
  extraReducers: {
    [userAuthenticate.fulfilled]: (state, action) => {
      action.payload.permissions.push('logout');
      socketIO.connector.connect();
      state.id = action.payload.user.id;
      state.email = action.payload.user.email;
      state.tokenId = action.payload.tokenId;
      state.permissions = action.payload.permissions;
    },
    [userAuthenticate.rejected]: (state) => {
      state.id = 0;
      state.email = '';
      state.tokenId = 0;
      state.permissions = ['login'];
    },
    [userLogout.fulfilled]: (state) => {
      state.id = 0;
      state.email = '';
      state.tokenId = 0;
      state.permissions = ['login'];
    },
    [userLogout.rejected]: (state) => {
      state.id = 0;
      state.email = '';
      state.tokenId = 0;
      state.permissions = ['login'];
    },
    [userLogin.fulfilled]: (state, action) => {
      state.id = action?.payload?.id || 0;
    },
    [userLogin.rejected]: (state) => {
      state.id = 0;
      state.email = '';
      state.tokenId = 0;
      state.permissions = ['login'];
    },
    [userLoginWithUUID.fulfilled]: (state, action) => {
      state.id = action?.payload?.id || 0;
    },
    [userLoginWithUUID.rejected]: (state) => {
      state.id = 0;
      state.email = '';
      state.tokenId = 0;
      state.permissions = ['login'];
    },
  },
});

export const {
  setUser,
  setPermissions,
} = appUser.actions;

export default appUser;
