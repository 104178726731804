const loaders = {
  alarm: {
    readAll: 'alarm.readAll',
    read: 'alarm.read',
    update: 'alarm.update',
    delete: 'alarm.delete',
    patch: 'alarm.patch',
    operator: {
      readAll: 'alarm.operator.readAll',
    },
    operatorNew: {
      readAll: 'alarm.operatorNew.readAll',
    },
    operatorPlanned: {
      readAll: 'alarm.operatorPlanned.readAll',
    },
    operatorCurrent: {
      readAll: 'alarm.operatorCurrent.readAll',
    },
    operatorClosed: {
      readAll: 'alarm.operatorClosed.readAll',
    },
    responder: {
      readAll: 'alarm.responder.readAll',
    },
  },
  client: {
    readAll: 'client.readAll',
    read: 'client.read',
    update: 'client.update',
    delete: 'client.delete',
  },
  location: {
    readAll: 'location.readAll',
    read: 'location.read',
    update: 'location.update',
    delete: 'location.delete',
  },
  permission: {
    readAll: 'permission.readAll',
    read: 'permission.read',
    update: 'permission.update',
    delete: 'permission.delete',
  },
  alarmSequence: {
    readAll: 'alarmSequence.readAll',
    read: 'alarmSequence.read',
    update: 'alarmSequence.update',
    delete: 'alarmSequence.delete',
  },
  geoGroup: {
    readAll: 'geoGroup.readAll',
    read: 'geoGroup.read',
    update: 'geoGroup.update',
    delete: 'geoGroup.delete',
  },
  permissionGroup: {
    readAll: 'permissionGroup.readAll',
    read: 'permissionGroup.read',
    update: 'permissionGroup.update',
    delete: 'permissionGroup.delete',
  },
  user: {
    authenticate: 'user.authenticate',
    delete: 'user.delete',
    login: 'user.login',
    userLoginWithUUID: 'user.loginWithUUID',
    logout: 'user.logout',
    readAll: 'user.readAll',
    read: 'user.read',
    update: 'user.update',
  },
  image: {
    uploadURL: 'image.upload.url',
  },
};

export default loaders;
