const locationRoutes = {
  readAll: '/location',
  update: '/location/update/:id',
  create: '/location/update/new',
  read: '/location/:id',
  generate: {
    update: (id) => `/location/update/${id}`,
    read: (id) => `/location/${id}`,
  },
  api: {
    readAll: '/location',
    generate: {
      update: (id) => {
        if (id) {
          return `/location/${id}`;
        }
        return '/location';
      },
      read: (id) => `/location/${id}`,
      remove: (id) => `/location/${id}`,
    },
  },
};

export default locationRoutes;
