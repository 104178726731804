const alarmSequenceRoutes = {
  readAll: '/alarmSequence',
  update: '/alarmSequence/update/:id',
  create: '/alarmSequence/update/new',
  read: '/alarmSequence/:id',
  generate: {
    update: (id) => `/alarmSequence/update/${id}`,
    read: (id) => `/alarmSequence/${id}`,
  },
  api: {
    readAll: '/alarmSequence',
    update: '/alarmSequence',
    generate: {
      update: (id) => {
        if (id) {
          return `/alarmSequence/${id}`;
        }
        return '/alarmSequence';
      },
      read: (id) => `/alarmSequence/${id}`,
      delete: (id) => `/alarmSequence/${id}`,
    },
  },
};

export default alarmSequenceRoutes;
