const clientRoutes = {
  readAll: '/client',
  update: '/client/update/:id',
  create: '/client/update/new',
  read: '/client/:id',
  generate: {
    update: (id) => `/client/update/${id}`,
    read: (id) => `/client/${id}`,
  },
  api: {
    readAll: '/client',
    generate: {
      update: (id) => {
        if (id) {
          return `/client/${id}`;
        }
        return '/client';
      },
      read: (id) => `/client/${id}`,
      remove: (id) => `/client/${id}`,
    },
  },
};

export default clientRoutes;
