/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import locationReadAll from '../thunks/location/readAll';
import locationUpdate from '../thunks/location/update';
import locationRead from '../thunks/location/read';
import locationDelete from '../thunks/location/delete';

const initialState = {
  locations: {},
  locationIds: [],
};

const location = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setUser(state, action) {
      state.id = action.payload;
    },
    setPermissions(state, action) {
      state.locations = action.payload;
    },
  },
  extraReducers: {
    [locationReadAll.fulfilled]: (state, action) => {
      const actionPermissions = Array.isArray(action?.payload) ? action.payload : [];
      const statePermissions = {};
      const statePermissionIds = [];
      actionPermissions.forEach((actionPermission) => {
        statePermissions[actionPermission.id] = actionPermission;
        statePermissionIds.push(actionPermission.id);
      });
      state.locations = statePermissions;
      state.locationIds = statePermissionIds;
    },
    [locationReadAll.rejected]: (state) => {
      state.locations = [];
    },
    [locationUpdate.fulfilled]: (state, action) => {
      if (!state.locationIds.includes(action.payload.id)) {
        state.locationIds.push(action.payload.id);
      }
      state.locations[action.payload.id] = action.payload;
    },
    [locationUpdate.rejected]: (state, action) => {
      console.log(action);
    },
    [locationRead.fulfilled]: (state, action) => {
      if (!state.locationIds.includes(action.payload.id)) {
        state.locationIds.push(action.payload.id);
      }
      state.locations[action.payload.id] = action.payload;
    },
    [locationRead.rejected]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.locations[id];
        state.locationIds = state.locationIds.filter((pId) => pId !== id);
      }
    },
    [locationDelete.fulfilled]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.locations[id];
        state.locationIds = state.locationIds.filter((pId) => pId !== id);
      }
    },
    [locationDelete.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const {
  setUser,
  setPermissions,
} = location.actions;

export default location;
