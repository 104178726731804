import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiFetch } from '../../utilities/appFetch';
import alarmRoutes from '../../components/alarm/alarmRoutes';


export const alarmDelete = createAsyncThunk(
  'thunk/alarm/delete',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.api.generate.delete(arg?.id), {
      method: 'DELETE',
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export const alarmOperatorClosedReadAll = createAsyncThunk(
  'thunk/alarm/operatorClosed/readAll',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.operatorClosed.api.readAll, {
      body: arg?.data,
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export const alarmOperatorCurrentReadAll = createAsyncThunk(
  'thunk/alarm/operatorCurrent/readAll',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.operatorCurrent.api.readAll, {
      body: arg?.data,
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export const alarmOperatorNewReadAll = createAsyncThunk(
  'thunk/alarm/operatorNew/readAll',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.operatorNew.api.readAll, {
      body: arg?.data,
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export const alarmOperatorPlannedReadAll = createAsyncThunk(
  'thunk/alarm/operatorPlanned/readAll',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.operatorPlanned.api.readAll, {
      body: arg?.data,
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export const alarmOperatorReadAll = createAsyncThunk(
  'thunk/alarm/operator/readAll',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.operator.api.readAll, {
      body: arg?.data,
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);


export const alarmPatch = createAsyncThunk(
  'thunk/alarm/patch',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.api.generate.patch(arg?.id), {
      body: arg?.data,
      method: 'PATCH',
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export const alarmRead = createAsyncThunk(
  'thunk/alarm/read',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.api.generate.read(arg?.id), {
      method: 'GET',
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export const alarmReadAll = createAsyncThunk(
  'thunk/alarm/readAll',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.api.readAll, {
      body: arg?.data,
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export const alarmResponderReadAll = createAsyncThunk(
  'thunk/alarm/responder/readAll',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.responder.api.readAll, {
      body: arg?.data,
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export const alarmUpdate = createAsyncThunk(
  'thunk/alarm/update',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmRoutes.api.generate.update(arg?.id), {
      body: arg?.data,
      method: 'POST',
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);


export const additionalServiceRead = createAsyncThunk(
  'thunk/alarm/additionalServiceRead',
  async ({parent_id, ...arg}, thunkAPI) => {
    try{
      if(!parent_id){
        throw new Error('cannot additionalServiceRead parent_id is required');
      }
      const response = await apiFetch(`alarm/additionalServices/${parent_id}`, {
        method: 'GET'
      });
      return [...response.result];

    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
