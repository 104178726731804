const geoGroupRoutes = {
  readAll: '/geoGroup',
  update: '/geoGroup/update/:id',
  create: '/geoGroup/update/new',
  read: '/geoGroup/:id',
  generate: {
    update: (id) => `/geoGroup/update/${id}`,
    read: (id) => `/geoGroup/${id}`,
  },
  api: {
    readAll: '/geoGroup',
    update: '/geoGroup',
    generate: {
      update: (id) => {
        if (id) {
          return `/geoGroup/${id}`;
        }
        return '/geoGroup';
      },
      read: (id) => `/geoGroup/${id}`,
      delete: (id) => `/geoGroup/${id}`,
    },
  },
};

export default geoGroupRoutes;
