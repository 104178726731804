/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  selectedItem: '',
};

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    closeMenu(state) {
      state.isOpen = false;
    },
    toggleMenu(state) {
      state.isOpen = !state.isOpen;
    },
    setSelectedItem(state, action) {
      state.selectedItem = action.payload;
      state.isOpen = false;
    },
  },
});

export const {
  closeMenu,
  toggleMenu,
  setSelectedItem,
} = menu.actions;

export default menu;
