import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiFetch } from '../../../utilities/appFetch';
import userRoutes from '../../../components/user/userRoutes';
import authenticate from './authenticate';

const login = createAsyncThunk(
  'thunk/user/login',
  async (arg, thunkAPI) => {
    const response = await apiFetch(userRoutes.api.login, {
      body: arg?.data,
      method: 'POST',
    });
    thunkAPI.dispatch(authenticate());
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export default login;
