import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiFetch } from '../../../utilities/appFetch';
import locationRoutes from '../../../components/location/locationRoutes';

const readAll = createAsyncThunk(
  'thunk/location/readAll',
  async (arg, thunkAPI) => {
    const response = await apiFetch(locationRoutes.api.readAll, {
      body: arg?.data,
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export default readAll;
