/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  headerHeight: 64,
  footerHeight: 52,
  currentBreakpoints: {
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true,
  },
};

const layout = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setHeaderHeight(state, action) {
      state.headerHeight = action.payload;
    },
    setFooterHeight(state, action) {
      state.footerHeight = action.payload;
    },
    setCurrentBreakpoints(state, action) {
      state.currentBreakpoints = {
        xs: true,
        ...action.payload,
      };
    },
  },
});

export const {
  setHeaderHeight,
  setFooterHeight,
  setCurrentBreakpoints,
} = layout.actions;

export default layout;
