import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiFetch } from '../../../utilities/appFetch';
import alarmSequenceRoutes from '../../../components/alarmSequence/alarmSequenceRoutes';

const readAll = createAsyncThunk(
  'thunk/alarmSequence/readAll',
  async (arg, thunkAPI) => {
    const response = await apiFetch(alarmSequenceRoutes.api.readAll, {
      body: arg?.data,
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export default readAll;
