/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import userAuthenticate from '../thunks/user/authenticate';
import loaders from '../../utilities/loaders';


import {
  alarmReadAll,
  alarmOperatorReadAll,
  alarmOperatorNewReadAll,
  alarmOperatorPlannedReadAll,
  alarmOperatorCurrentReadAll,
  alarmOperatorClosedReadAll,
  alarmResponderReadAll,
  alarmRead,
  alarmUpdate,
  alarmDelete,
  alarmPatch,
} from '../thunks/alarm';

import clientReadAll from '../thunks/client/readAll';
import clientRead from '../thunks/client/read';
import clientUpdate from '../thunks/client/update';
import clientDelete from '../thunks/client/delete';
import locationReadAll from '../thunks/location/readAll';
import locationRead from '../thunks/location/read';
import locationUpdate from '../thunks/location/update';
import locationDelete from '../thunks/location/delete';
import permissionReadAll from '../thunks/permission/readAll';
import permissionRead from '../thunks/permission/read';
import permissionUpdate from '../thunks/permission/update';
import permissionDelete from '../thunks/permission/delete';
import alarmSequenceReadAll from '../thunks/alarmSequence/readAll';
import alarmSequenceRead from '../thunks/alarmSequence/read';
import alarmSequenceUpdate from '../thunks/alarmSequence/update';
import alarmSequenceDelete from '../thunks/alarmSequence/delete';
import geoGroupReadAll from '../thunks/geoGroup/readAll';
import geoGroupRead from '../thunks/geoGroup/read';
import geoGroupUpdate from '../thunks/geoGroup/update';
import geoGroupDelete from '../thunks/geoGroup/delete';
import permissionGroupReadAll from '../thunks/permissionGroup/readAll';
import permissionGroupRead from '../thunks/permissionGroup/read';
import permissionGroupUpdate from '../thunks/permissionGroup/update';
import permissionGroupDelete from '../thunks/permissionGroup/delete';
import userReadAll from '../thunks/user/readAll';
import userRead from '../thunks/user/read';
import userUpdate from '../thunks/user/update';
import userDelete from '../thunks/user/delete';
import userLogin from '../thunks/user/login';
import userLoginWithUUID from '../thunks/user/loginWithUUID';
import userLogout from '../thunks/user/logout';

import i18n from '../../i18n';
const t = (s, opts) => i18n.t(s, opts);

const noPending = true;
const noFulfilled = true;

const addSnack = (state, snack, key) => {
  state.snacks[key] = snack;
  state.snackIds = Object.keys(state.snacks);
  return state;
};

const removeSnack = (state, snackKey) => {
  delete state.snacks[snackKey];
  state.snackIds = Object.keys(state.snacks);
  return state;
};

const constructThunkReducers = (thunkConfigs) => {
  const reducers = {};
  thunkConfigs.forEach(((config) => {
    if (!noPending) {
      reducers[config.thunk.pending] = (state) => {
        addSnack(state, {
          content: config.responsePending,
          variant: 'info',
        }, config.loader);
      };
    }
    if (!noFulfilled) {
      reducers[config.thunk.fulfilled] = (state) => {
        addSnack(state, {
          content: config.responseSuccess,
          variant: 'success',
        }, config.loader);
      };
    }
    reducers[config.thunk.rejected] = (state) => {
      addSnack(state, {
        content: config.responseFail,
        variant: 'error',
      }, config.loader);
    };
  }));
  return reducers;
};

const thunkReducers = constructThunkReducers([
  {
    thunk: alarmReadAll,
    loader: loaders.alarm.readAll,
    responsePending: t('Alarms loading'),
    responseSuccess: t('Alarms loaded'),
    responseFail: t('Alarms not loaded'),
  }, {
    thunk: alarmOperatorReadAll,
    loader: loaders.alarm.operator.readAll,
    responsePending: t('Alarms loading'),
    responseSuccess: t('Alarms loaded'),
    responseFail: t('Alarms not loaded'),
  }, {
    thunk: alarmOperatorNewReadAll,
    loader: loaders.alarm.operatorNew.readAll,
    responsePending: t('Alarms loading'),
    responseSuccess: t('Alarms loaded'),
    responseFail: t('Alarms not loaded'),
  }, {
    thunk: alarmOperatorPlannedReadAll,
    loader: loaders.alarm.operatorPlanned.readAll,
    responsePending: t('Alarms loading'),
    responseSuccess: t('Alarms loaded'),
    responseFail: t('Alarms not loaded'),
  }, {
    thunk: alarmOperatorCurrentReadAll,
    loader: loaders.alarm.operatorCurrent.readAll,
    responsePending: t('Alarms loading'),
    responseSuccess: t('Alarms loaded'),
    responseFail: t('Alarms not loaded'),
  }, {
    thunk: alarmOperatorClosedReadAll,
    loader: loaders.alarm.operatorClosed.readAll,
    responsePending: t('Alarms loading'),
    responseSuccess: t('Alarms loaded'),
    responseFail: t('Alarms not loaded'),
  }, {
    thunk: alarmResponderReadAll,
    loader: loaders.alarm.responder.readAll,
    responsePending: t('Alarms loading'),
    responseSuccess: t('Alarms loaded'),
    responseFail: t('Alarms not loaded'),
  }, {
    thunk: alarmRead,
    loader: loaders.alarm.read,
    responsePending: t('Alarm loading'),
    responseSuccess: t('Alarm loaded'),
    responseFail: t('Alarm not loaded'),
  }, {
    thunk: alarmUpdate,
    loader: loaders.alarm.update,
    responsePending: t('Alarm updating'),
    responseSuccess: t('Alarm updated'),
    responseFail: t('Alarm not updated'),
  }, {
    thunk: alarmDelete,
    loader: loaders.alarm.delete,
    responsePending: t('Alarm deleting'),
    responseSuccess: t('Alarm deleted'),
    responseFail: t('Alarm not deleted'),
  }, {
    thunk: alarmPatch,
    loader: loaders.alarm.patch,
    responsePending: t('Alarm patching'),
    responseSuccess: t('Alarm patched'),
    responseFail: t('Alarm unchanged'),
  }, {
    thunk: clientReadAll,
    loader: loaders.client.readAll,
    responsePending: t('Clients loading'),
    responseSuccess: t('Clients loaded'),
    responseFail: t('Clients not loaded'),
  }, {
    thunk: clientRead,
    loader: loaders.client.read,
    responsePending: t('Client loading'),
    responseSuccess: t('Client loaded'),
    responseFail: t('Client not loaded'),
  }, {
    thunk: clientUpdate,
    loader: loaders.client.update,
    responsePending: t('Client updating'),
    responseSuccess: t('Client updated'),
    responseFail: t('Client not updated'),
  }, {
    thunk: clientDelete,
    loader: loaders.client.delete,
    responsePending: t('Client deleting'),
    responseSuccess: t('Client deleted'),
    responseFail: t('Client not deleted'),
  }, {
    thunk: locationReadAll,
    loader: loaders.location.readAll,
    responsePending: t('Locations loading'),
    responseSuccess: t('Locations loaded'),
    responseFail: t('Locations not loaded'),
  }, {
    thunk: locationRead,
    loader: loaders.location.read,
    responsePending: t('Location loading'),
    responseSuccess: t('Location loaded'),
    responseFail: t('Location not loaded'),
  }, {
    thunk: locationUpdate,
    loader: loaders.location.update,
    responsePending: t('Location updating'),
    responseSuccess: t('Location updated'),
    responseFail: t('Location not updated'),
  }, {
    thunk: locationDelete,
    loader: loaders.location.delete,
    responsePending: t('Location deleting'),
    responseSuccess: t('Location deleted'),
    responseFail: t('Location not deleted'),
  }, {
    thunk: permissionReadAll,
    loader: loaders.permission.readAll,
    responsePending: t('Permissions loading'),
    responseSuccess: t('Permissions loaded'),
    responseFail: t('Permissions not loaded'),
  }, {
    thunk: permissionRead,
    loader: loaders.permission.read,
    responsePending: t('Permission loading'),
    responseSuccess: t('Permission loaded'),
    responseFail: t('Permission not loaded'),
  }, {
    thunk: permissionUpdate,
    loader: loaders.permission.update,
    responsePending: t('Permission updating'),
    responseSuccess: t('Permission updated'),
    responseFail: t('Permission not updated'),
  }, {
    thunk: permissionDelete,
    loader: loaders.permission.delete,
    responsePending: t('Permission deleting'),
    responseSuccess: t('Permission deleted'),
    responseFail: t('Permission not deleted'),
  }, {
    thunk: alarmSequenceReadAll,
    loader: loaders.alarmSequence.readAll,
    responsePending: t('Alarm sequences loading'),
    responseSuccess: t('Alarm sequences loaded'),
    responseFail: t('Alarm sequences not loaded'),
  }, {
    thunk: alarmSequenceRead,
    loader: loaders.alarmSequence.read,
    responsePending: t('Alarm sequence loading'),
    responseSuccess: t('Alarm sequence loaded'),
    responseFail: t('Alarm sequence not loaded'),
  }, {
    thunk: alarmSequenceUpdate,
    loader: loaders.alarmSequence.update,
    responsePending: t('Alarm sequence updating'),
    responseSuccess: t('Alarm sequence updated'),
    responseFail: t('Alarm sequence not updated'),
  }, {
    thunk: alarmSequenceDelete,
    loader: loaders.alarmSequence.delete,
    responsePending: t('Alarm sequence deleting'),
    responseSuccess: t('Alarm sequence deleted'),
    responseFail: t('Alarm sequence not deleted'),
  }, {
    thunk: geoGroupReadAll,
    loader: loaders.geoGroup.readAll,
    responsePending: t('Geo groups loading'),
    responseSuccess: t('Geo groups loaded'),
    responseFail: t('Geo groups not loaded'),
  }, {
    thunk: geoGroupRead,
    loader: loaders.geoGroup.read,
    responsePending: t('Geo group loading'),
    responseSuccess: t('Geo group loaded'),
    responseFail: t('Geo group not loaded'),
  }, {
    thunk: geoGroupUpdate,
    loader: loaders.geoGroup.update,
    responsePending: t('Geo group updating'),
    responseSuccess: t('Geo group updated'),
    responseFail: t('Geo group not updated'),
  }, {
    thunk: geoGroupDelete,
    loader: loaders.geoGroup.delete,
    responsePending: t('Geo group deleting'),
    responseSuccess: t('Geo group deleted'),
    responseFail: t('Geo group not deleted'),
  }, {
    thunk: permissionGroupReadAll,
    loader: loaders.permissionGroup.readAll,
    responsePending: t('Permission groups loading'),
    responseSuccess: t('Permission groups loaded'),
    responseFail: t('Permission groups not loaded'),
  }, {
    thunk: permissionGroupRead,
    loader: loaders.permissionGroup.read,
    responsePending: t('Permission group loading'),
    responseSuccess: t('Permission group loaded'),
    responseFail: t('Permission group not loaded'),
  }, {
    thunk: permissionGroupUpdate,
    loader: loaders.permissionGroup.update,
    responsePending: t('Permission group updating'),
    responseSuccess: t('Permission group updated'),
    responseFail: t('Permission group not updated'),
  }, {
    thunk: permissionGroupDelete,
    loader: loaders.permissionGroup.delete,
    responsePending: t('Permission group deleting'),
    responseSuccess: t('Permission group deleted'),
    responseFail: t('Permission group not deleted'),
  }, {
    thunk: userReadAll,
    loader: loaders.user.readAll,
    responsePending: t('Users loading'),
    responseSuccess: t('Users loaded'),
    responseFail: t('Users not loaded'),
  }, {
    thunk: userRead,
    loader: loaders.user.read,
    responsePending: t('User loading'),
    responseSuccess: t('User loaded'),
    responseFail: t('User not loaded'),
  }, {
    thunk: userUpdate,
    loader: loaders.user.update,
    responsePending: t('User updating'),
    responseSuccess: t('User updated'),
    responseFail: t('User not updated'),
  }, {
    thunk: userDelete,
    loader: loaders.user.delete,
    responsePending: t('User deleting'),
    responseSuccess: t('User deleted'),
    responseFail: t('User not deleted'),
  }, {
    thunk: userAuthenticate,
    loader: loaders.user.authenticate,
    responsePending: t('User authenticating'),
    responseSuccess: t('User authenticated'),
    responseFail: t('User not authenticated'),
  }, {
    thunk: userLogin,
    loader: loaders.user.login,
    responsePending: t('User authorizing'),
    responseSuccess: t('User authorized'),
    responseFail: t('User not authorized'),
  }, {
    thunk: userLoginWithUUID,
    loader: loaders.user.loginWithUUID,
    responsePending: t('User authorizing'),
    responseSuccess: t('User authorized'),
    responseFail: t('User not authorized'),
  }, {
    thunk: userLogout,
    loader: loaders.user.logout,
    responsePending: t('User logging out'),
    responseSuccess: t('User logged out'),
    responseFail: t('User not logged out'),
  },
]);

const initialState = {
  snacks: {},
  snackIds: [],
};

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    removeSnack(state, action) {
      removeSnack(state, action.payload);
    },
    removePending(state, action) {
      state.snackIds = state.snackIds
        .filter((id) => id !== action.payload);
    },
    setCurrentBreakpoints(state, action) {
      state.currentBreakpoints = {
        xs: true,
        ...action.payload,
      };
    },
  },
  extraReducers: thunkReducers,
});

export const {
  closeSnack,
  removePending,
} = snackbar.actions;

export default snackbar;
