import i18n from 'i18next';
import i18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import sysConfig from './config';
import BackendAdapter from "i18next-multiload-backend-adapter";
import LanguageDetector from 'i18next-browser-languagedetector';



i18n
  .use(BackendAdapter)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: false,

    saveMissing: true,
    returnEmptyString: false,
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
    backend:{
      backend: i18NextHttpBackend,
      backendOption: {
        // addPath: `${sysConfig.PUBLIC_URL}/locale?lng={{lng}}&ns={{ns}}`,
        addPath: `${sysConfig.PUBLIC_URL}/locale/{{ns}}/{{lng}}`,
        loadPath: `${sysConfig.PUBLIC_URL}/locale?lng={{lng}}&ns={{ns}}`,
        crossDomain: true,
        requestOptions: {
          mode: 'cors',
          credentials: 'same-origin',
          cache: 'default',
        },

        allowMultiLoading: true,
      }
    },
    react: {
      wait: true,
      useSuspense: false,
    },

  });

export default i18n;
