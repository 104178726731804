import socketIOClient from 'socket.io-client';
import sysConfig from '../config';

const socketIO = socketIOClient(sysConfig.PUBLIC_URL, { autoConnect: false });

const requestRoom = (room) => {
  socketIO.emit('roomRequest', {
    room,
  });
};

export default {
  connector: socketIO,
  requestRoom,
};
