/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import loaders from '../../utilities/loaders';
import clientReadAll from '../thunks/client/readAll';
import locationReadAll from '../thunks/location/readAll';
import permissionReadAll from '../thunks/permission/readAll';
import permissionUpdate from '../thunks/permission/update';
import permissionRead from '../thunks/permission/read';
import permissionDelete from '../thunks/permission/delete';
import alarmSequenceReadAll from '../thunks/alarmSequence/readAll';
import alarmSequenceUpdate from '../thunks/alarmSequence/update';
import alarmSequenceRead from '../thunks/alarmSequence/read';
import alarmSequenceDelete from '../thunks/alarmSequence/delete';
import geoGroupReadAll from '../thunks/geoGroup/readAll';
import geoGroupUpdate from '../thunks/geoGroup/update';
import geoGroupRead from '../thunks/geoGroup/read';
import geoGroupDelete from '../thunks/geoGroup/delete';
import permissionGroupReadAll from '../thunks/permissionGroup/readAll';
import permissionGroupUpdate from '../thunks/permissionGroup/update';
import permissionGroupRead from '../thunks/permissionGroup/read';
import permissionGroupDelete from '../thunks/permissionGroup/delete';
import userAuthenticate from '../thunks/user/authenticate';
import userLogin from '../thunks/user/login';
import userLoginWithUUID from '../thunks/user/loginWithUUID';
import userLogout from '../thunks/user/logout';
import userReadAll from '../thunks/user/readAll';
import userUpdate from '../thunks/user/update';
import userRead from '../thunks/user/read';
import userDelete from '../thunks/user/delete';
import locationUpdate from '../thunks/location/update';
import locationRead from '../thunks/location/read';
import locationDelete from '../thunks/location/delete';
import clientUpdate from '../thunks/client/update';
import clientRead from '../thunks/client/read';
import clientDelete from '../thunks/client/delete';


import {
  alarmReadAll,
  alarmOperatorReadAll,
  alarmOperatorNewReadAll,
  alarmOperatorPlannedReadAll,
  alarmOperatorCurrentReadAll,
  alarmOperatorClosedReadAll,
  alarmResponderReadAll,
  alarmUpdate,
  alarmRead,
  alarmDelete,
  alarmPatch,
} from '../thunks/alarm';



const addLoader = (state, loaderKey) => {
  if (state.loaders[loaderKey]) {
    state.loaders[loaderKey] += 1;
  } else {
    state.loaders[loaderKey] = 1;
  }
  state.isLoading = Object.keys(state.loaders).length > 0;
  return state;
};

const removeLoader = (state, loaderKey) => {
  if (state.loaders[loaderKey] === 1) {
    delete state.loaders[loaderKey];
  } else if (state.loaders[loaderKey] > 1) {
    state.loaders[loaderKey] -= 1;
  }
  state.isLoading = Object.keys(state.loaders).length > 0;
  return state;
};

const constructThunkReducers = (thunkConfigs) => {
  const reducers = {};
  thunkConfigs.forEach(((config) => {
    reducers[config.thunk.pending] = (state) => {
      addLoader(state, config.loader);
    };
    reducers[config.thunk.fulfilled] = (state) => {
      removeLoader(state, config.loader);
    };
    reducers[config.thunk.rejected] = (state) => {
      removeLoader(state, config.loader);
    };
  }));
  return reducers;
};

const thunkReducers = constructThunkReducers([
  {
    thunk: alarmReadAll,
    loader: loaders.alarm.readAll,
  }, {
    thunk: alarmOperatorReadAll,
    loader: loaders.alarm.operator.readAll,
  }, {
    thunk: alarmOperatorNewReadAll,
    loader: loaders.alarm.operatorNew.readAll,
  }, {
    thunk: alarmOperatorPlannedReadAll,
    loader: loaders.alarm.operatorPlanned.readAll,
  }, {
    thunk: alarmOperatorCurrentReadAll,
    loader: loaders.alarm.operatorCurrent.readAll,
  }, {
    thunk: alarmOperatorClosedReadAll,
    loader: loaders.alarm.operatorClosed.readAll,
  }, {
    thunk: alarmResponderReadAll,
    loader: loaders.alarm.responder.readAll,
  }, {
    thunk: alarmRead,
    loader: loaders.alarm.read,
  }, {
    thunk: alarmUpdate,
    loader: loaders.alarm.update,
  }, {
    thunk: alarmDelete,
    loader: loaders.alarm.delete,
  }, {
    thunk: alarmPatch,
    loader: loaders.alarm.patch,
  }, {
    thunk: clientReadAll,
    loader: loaders.client.readAll,
  }, {
    thunk: clientRead,
    loader: loaders.client.read,
  }, {
    thunk: clientUpdate,
    loader: loaders.client.update,
  }, {
    thunk: clientDelete,
    loader: loaders.client.delete,
  }, {
    thunk: locationReadAll,
    loader: loaders.location.readAll,
  }, {
    thunk: locationRead,
    loader: loaders.location.read,
  }, {
    thunk: locationUpdate,
    loader: loaders.location.update,
  }, {
    thunk: locationDelete,
    loader: loaders.location.delete,
  }, {
    thunk: permissionReadAll,
    loader: loaders.permission.readAll,
  }, {
    thunk: permissionRead,
    loader: loaders.permission.read,
  }, {
    thunk: permissionUpdate,
    loader: loaders.permission.update,
  }, {
    thunk: permissionDelete,
    loader: loaders.permission.delete,
  }, {
    thunk: alarmSequenceReadAll,
    loader: loaders.alarmSequence.readAll,
  }, {
    thunk: alarmSequenceRead,
    loader: loaders.alarmSequence.read,
  }, {
    thunk: alarmSequenceUpdate,
    loader: loaders.alarmSequence.update,
  }, {
    thunk: alarmSequenceDelete,
    loader: loaders.alarmSequence.delete,
  }, {
    thunk: geoGroupReadAll,
    loader: loaders.geoGroup.readAll,
  }, {
    thunk: geoGroupRead,
    loader: loaders.geoGroup.read,
  }, {
    thunk: geoGroupUpdate,
    loader: loaders.geoGroup.update,
  }, {
    thunk: geoGroupDelete,
    loader: loaders.geoGroup.delete,
  }, {
    thunk: permissionGroupReadAll,
    loader: loaders.permissionGroup.readAll,
  }, {
    thunk: permissionGroupRead,
    loader: loaders.permissionGroup.read,
  }, {
    thunk: permissionGroupUpdate,
    loader: loaders.permissionGroup.update,
  }, {
    thunk: permissionGroupDelete,
    loader: loaders.permissionGroup.delete,
  }, {
    thunk: userReadAll,
    loader: loaders.user.readAll,
  }, {
    thunk: userRead,
    loader: loaders.user.read,
  }, {
    thunk: userUpdate,
    loader: loaders.user.update,
  }, {
    thunk: userDelete,
    loader: loaders.user.delete,
  }, {
    thunk: userAuthenticate,
    loader: loaders.user.authenticate,
  }, {
    thunk: userLogin,
    loader: loaders.user.login,
  }, {
    thunk: userLoginWithUUID,
    loader: loaders.user.loginWithUUID,
  }, {
    thunk: userLogout,
    loader: loaders.user.logout,
  },
]);

const initialState = {
  loaders: {},
  isLoading: false,
};

const loading = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading(state, action) {
      const loaderKey = action.payload;
      addLoader(state, loaderKey);
    },
    stopLoading(state, action) {
      const loaderKey = action.payload;
      removeLoader(state, loaderKey);
    },
  },
  extraReducers: thunkReducers,
});

export const {
  startLoading,
  stopLoading,
} = loading.actions;

export default loading;
