/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import alarmSequenceReadAll from '../thunks/alarmSequence/readAll';
import alarmSequenceUpdate from '../thunks/alarmSequence/update';
import alarmSequenceRead from '../thunks/alarmSequence/read';
import alarmSequenceDelete from '../thunks/alarmSequence/delete';

const initialState = {
  alarmSequences: {},
  alarmSequenceIds: [],
};

const alarmSequence = createSlice({
  name: 'alarmSequence',
  initialState,
  reducers: {
    setUser(state, action) {
      state.id = action.payload;
    },
    setPermissions(state, action) {
      state.alarmSequences = action.payload;
    },
  },
  extraReducers: {
    [alarmSequenceReadAll.fulfilled]: (state, action) => {
      const actionPermissions = Array.isArray(action?.payload) ? action.payload : [];
      const statePermissions = {};
      const statePermissionIds = [];
      actionPermissions.forEach((actionPermission) => {
        statePermissions[actionPermission.id] = actionPermission;
        statePermissionIds.push(actionPermission.id);
      });
      state.alarmSequences = statePermissions;
      state.alarmSequenceIds = statePermissionIds;
    },
    [alarmSequenceReadAll.rejected]: (state) => {
      state.alarmSequences = [];
    },
    [alarmSequenceUpdate.fulfilled]: (state, action) => {
      if (!state.alarmSequenceIds.includes(action.payload.id)) {
        state.alarmSequenceIds.push(action.payload.id);
      }
      state.alarmSequences[action.payload.id] = action.payload;
    },
    [alarmSequenceUpdate.rejected]: (state, action) => {
      console.log(action);
    },
    [alarmSequenceRead.fulfilled]: (state, action) => {
      if (!state.alarmSequenceIds.includes(action.payload.id)) {
        state.alarmSequenceIds.push(action.payload.id);
      }
      state.alarmSequences[action.payload.id] = action.payload;
    },
    [alarmSequenceRead.rejected]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.alarmSequences[id];
        state.alarmSequenceIds = state.alarmSequenceIds.filter((pId) => pId !== id);
      }
    },
    [alarmSequenceDelete.fulfilled]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.alarmSequences[id];
        state.alarmSequenceIds = state.alarmSequenceIds.filter((pId) => pId !== id);
      }
    },
    [alarmSequenceDelete.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const {
  setUser,
  setPermissions,
} = alarmSequence.actions;

export default alarmSequence;
