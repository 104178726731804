import React, { Suspense, useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/hr';
import { SnackbarProvider } from 'notistack';
import store from './redux/store';
import socketIO from './socketIO/socketIO';
import colors from './styles/colors';

moment.locale('en');

const Root = React.lazy(() => import('./layout/Root'));

const theme = createTheme({
  palette: {
    primary: {
      main: colors.zBlue,
    },
    secondary: {
      main: colors.zRed,
    },
    accent: {
      main: colors.zDarkBlue,
    }
  },

  spacing: 8,
  // typography: {
  //   fontSize: 12,
  // },

  //FIXME: why is variant mapping not working!!!!!
  overrides: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'subtitle2',
        h6: 'subtitle2',
        subtitle1: 'subtitle1',
        subtitle2: 'subtitle2',
        body1: 'body1',
        body2: 'body2',
      },
    },
  },
});


function App() {
  const { i18n } = useTranslation();


  console.log("App language:", i18n.language, i18n);

  useEffect(() => {
    socketIO.connector.onAny((event, ...args) => {
      console.log(event, args);
    });
  });

  return (
    <>
      <CssBaseline />
      <Provider store={store}>
        <HashRouter>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={30}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
                <Suspense fallback="Loading">
                  <Root />
                </Suspense>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </HashRouter>
      </Provider>
    </>
  );
}

export default App;
