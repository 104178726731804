/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import clientReadAll from '../thunks/client/readAll';
import clientUpdate from '../thunks/client/update';
import clientRead from '../thunks/client/read';
import clientDelete from '../thunks/client/delete';

const initialState = {
  clients: {},
  clientIds: [],
};

const client = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setUser(state, action) {
      state.id = action.payload;
    },
    setPermissions(state, action) {
      state.clients = action.payload;
    },
  },
  extraReducers: {
    [clientReadAll.fulfilled]: (state, action) => {
      const actionPermissions = Array.isArray(action?.payload) ? action.payload : [];
      const statePermissions = {};
      const statePermissionIds = [];
      actionPermissions.forEach((actionPermission) => {
        statePermissions[actionPermission.id] = actionPermission;
        statePermissionIds.push(actionPermission.id);
      });
      state.clients = statePermissions;
      state.clientIds = statePermissionIds;
    },
    [clientReadAll.rejected]: (state) => {
      state.clients = [];
    },
    [clientUpdate.fulfilled]: (state, action) => {
      if (!state.clientIds.includes(action.payload.id)) {
        state.clientIds.push(action.payload.id);
      }
      state.clients[action.payload.id] = action.payload;
    },
    [clientUpdate.rejected]: (state, action) => {
      console.log(action);
    },
    [clientRead.fulfilled]: (state, action) => {
      if (!state.clientIds.includes(action.payload.id)) {
        state.clientIds.push(action.payload.id);
      }
      state.clients[action.payload.id] = action.payload;
    },
    [clientRead.rejected]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.clients[id];
        state.clientIds = state.clientIds.filter((pId) => pId !== id);
      }
    },
    [clientDelete.fulfilled]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.clients[id];
        state.clientIds = state.clientIds.filter((pId) => pId !== id);
      }
    },
    [clientDelete.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const {
  setUser,
  setPermissions,
} = client.actions;

export default client;
