import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiFetch } from '../../../utilities/appFetch';
import userRoutes from '../../../components/user/userRoutes';

const update = createAsyncThunk(
  'thunk/user/delete',
  async (arg, thunkAPI) => {
    const response = await apiFetch(userRoutes.api.generate.delete(arg?.id), {
      method: 'DELETE',
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export default update;
