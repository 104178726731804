/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import userReadAll from '../thunks/user/readAll';
import userUpdate from '../thunks/user/update';
import userRead from '../thunks/user/read';
import userDelete from '../thunks/user/delete';

const initialState = {
  users: {},
  userIds: [],
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.id = action.payload;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
  },
  extraReducers: {
    [userReadAll.fulfilled]: (state, action) => {
      const actionUsers = Array.isArray(action?.payload) ? action.payload : [];
      const stateUsers = {};
      const stateUserIds = [];
      actionUsers.forEach((actionUser) => {
        stateUsers[actionUser.id] = actionUser;
        stateUserIds.push(actionUser.id);
      });
      state.users = stateUsers;
      state.userIds = stateUserIds;
    },
    [userReadAll.rejected]: (state) => {
      state.users = [];
    },
    [userUpdate.fulfilled]: (state, action) => {
      if (!state.userIds.includes(action.payload.id)) {
        state.userIds.push(action.payload.id);
      }
      state.users[action.payload.id] = action.payload;
    },
    [userUpdate.rejected]: (state, action) => {
      console.log(action);
    },
    [userRead.fulfilled]: (state, action) => {
      if (!state.userIds.includes(action.payload.id)) {
        state.userIds.push(action.payload.id);
      }
      state.users[action.payload.id] = action.payload;
    },
    [userRead.rejected]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.users[id];
        state.userIds = state.userIds.filter((pId) => pId !== id);
      }
    },
    [userDelete.fulfilled]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.users[id];
        state.userIds = state.userIds.filter((pId) => pId !== id);
      }
    },
    [userDelete.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const {
  setUser,
  setPermissions,
} = user.actions;

export default user;
