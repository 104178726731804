/* eslint-disable no-restricted-globals */


export const alarmZones = {
  USER_ALARM: '0', //       return t('Alarm patrol - user alarm');
  DEVICE_ALARM: '1', //       return t('Alarm patrol');
  MONITORING: '2',//       return t('Monitoring');
  // EVENT_GUARD: '3',//       return t('Monitoring'); ??? wtf why again?
  GUARDING: '4', //       return t('Guarding');
}



const config = {

  PUBLIC_URL: process.env.NODE_ENV ==='development'
    ? location.protocol+'//'+location.host.replace(':3000' ,':4000')
    : location.protocol+'//'+location.host,

  REACT_APP_LOCALSTORAGE_LAST_POLICY_CHANGE_ISO8601: process.env.REACT_APP_LOCALSTORAGE_LAST_POLICY_CHANGE_ISO8601 || '2020-10-19T17:00:00.000+02:00',

  defaultWorkflowVersion: process.env.DEFAULT_WORKFLOW_VERSION || '1',
};

export default config;
