import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiFetch } from '../../../utilities/appFetch';
import userRoutes from '../../../components/user/userRoutes';

const authenticate = createAsyncThunk(
  'thunk/user/authenticate',
  async (arg, thunkAPI) => {
    const response = await apiFetch(userRoutes.api.authenticate, {});
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export default authenticate;
