const userRoutes = {
  readAll: '/user',
  update: '/user/update/:id',
  create: '/user/update/new',
  read: '/user/:id',
  login: '/user/login',
  loginWithUUID: '/user/login/uuid/:uuid',
  logout: '/user/logout',
  generate: {
    update: (id) => `/user/update/${id}`,
    read: (id) => `/user/${id}`,
  },
  api: {
    readAll: '/user',
    login: '/user/login',
    loginWithUUID: '/user/login/uuid',
    logout: '/user/logout',
    authenticate: '/user/authenticate',
    generate: {
      update: (id) => {
        if (id) {
          return `/user/${id}`;
        }
        return '/user';
      },
      read: (id) => `/user/${id}`,
      delete: (id) => `/user/${id}`,
    },
  },
};

export default userRoutes;
