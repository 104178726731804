/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import permissionGroupReadAll from '../thunks/permissionGroup/readAll';
import permissionGroupUpdate from '../thunks/permissionGroup/update';
import permissionGroupRead from '../thunks/permissionGroup/read';
import permissionGroupDelete from '../thunks/permissionGroup/delete';

const initialState = {
  permissionGroups: {},
  permissionGroupIds: [],
};

const permissionGroup = createSlice({
  name: 'permissionGroup',
  initialState,
  reducers: {
    setUser(state, action) {
      state.id = action.payload;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
  },
  extraReducers: {
    [permissionGroupReadAll.fulfilled]: (state, action) => {
      const actionPermissionGroups = Array.isArray(action?.payload) ? action.payload : [];
      const statePermissionGroups = {};
      const statePermissionGroupIds = [];
      actionPermissionGroups.forEach((actionPermissionGroup) => {
        statePermissionGroups[actionPermissionGroup.id] = actionPermissionGroup;
        statePermissionGroupIds.push(actionPermissionGroup.id);
      });
      state.permissionGroups = statePermissionGroups;
      state.permissionGroupIds = statePermissionGroupIds;
    },
    [permissionGroupReadAll.rejected]: (state) => {
      state.permissionGroups = [];
    },
    [permissionGroupUpdate.fulfilled]: (state, action) => {
      if (!state.permissionGroupIds.includes(action.payload.id)) {
        state.permissionGroupIds.push(action.payload.id);
      }
      state.permissionGroups[action.payload.id] = action.payload;
    },
    [permissionGroupUpdate.rejected]: (state, action) => {
      console.log(action);
    },
    [permissionGroupRead.fulfilled]: (state, action) => {
      if (!state.permissionGroupIds.includes(action.payload.id)) {
        state.permissionGroupIds.push(action.payload.id);
      }
      state.permissionGroups[action.payload.id] = action.payload;
    },
    [permissionGroupRead.rejected]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.permissionGroups[id];
        state.permissionGroupIds = state.permissionGroupIds.filter((pId) => pId !== id);
      }
    },
    [permissionGroupDelete.fulfilled]: (state, action) => {
      const id = action?.meta?.arg?.id || 0;
      if (id) {
        delete state.permissionGroups[id];
        state.permissionGroupIds = state.permissionGroupIds.filter((pId) => pId !== id);
      }
    },
    [permissionGroupDelete.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const {
  setUser,
  setPermissions,
} = permissionGroup.actions;

export default permissionGroup;
