const permissionRoutes = {
  readAll: '/permission',
  update: '/permission/update/:id',
  create: '/permission/update/new',
  read: '/permission/:id',
  generate: {
    update: (id) => `/permission/update/${id}`,
    read: (id) => `/permission/${id}`,
  },
  api: {
    readAll: '/permission',
    update: '/permission',
    generate: {
      update: (id) => {
        if (id) {
          return `/permission/${id}`;
        }
        return '/permission';
      },
      read: (id) => `/permission/${id}`,
      delete: (id) => `/permission/${id}`,
    },
  },
};

export default permissionRoutes;
