const permissionGroupRoutes = {
  readAll: '/permissionGroup',
  update: '/permissionGroup/update/:id',
  create: '/permissionGroup/update/new',
  read: '/permissionGroup/:id',
  generate: {
    update: (id) => `/permissionGroup/update/${id}`,
    read: (id) => `/permissionGroup/${id}`,
  },
  api: {
    readAll: '/permissionGroup',
    generate: {
      update: (id) => {
        if (id) {
          return `/permissionGroup/${id}`;
        }
        return '/permissionGroup';
      },
      read: (id) => `/permissionGroup/${id}`,
      delete: (id) => `/permissionGroup/${id}`,
    },
  },
};

export default permissionGroupRoutes;
