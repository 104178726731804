import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiFetch } from '../../../utilities/appFetch';
import geoGroupRoutes from '../../../routes/geoGroupRoutes';

const update = createAsyncThunk(
  'thunk/geoGroup/delete',
  async (arg, thunkAPI) => {
    const response = await apiFetch(geoGroupRoutes.api.generate.delete(arg?.id), {
      method: 'DELETE',
    });
    if (!response?.success) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.result;
  },
);

export default update;
