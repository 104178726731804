/* eslint-disable no-console */
import qs from 'qs';
import { v4 as uuidV4 } from 'uuid';
import urlJoin from 'url-join';
import sysConfig from '../config';

export const callFetch = async (url, options) => {
  const loaderKey = uuidV4();
  // console.debug('callFetch', {
  //   content: 'Loading...',
  //   key: loaderKey,
  // }, 0);
  try {
    let fetchUrl = url;

    const fetchOptions = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      ...options,
    };

    if (!fetchOptions.headers) {
      fetchOptions.headers = {};
    }

    if (fetchOptions.headers['Content-Type'] === undefined) {
      fetchOptions.headers['Content-Type'] = 'application/json';
    } else if (fetchOptions.headers['Content-Type'] === false) {
      delete fetchOptions.headers['Content-Type'];
    }

    if (fetchOptions.body) {
      if (['GET', 'DELETE'].includes(fetchOptions.method)) {
        fetchUrl += `?${qs.stringify(fetchOptions.body)}`;
        delete fetchOptions.body;
      } else if (fetchOptions.headers['Content-Type'] === 'application/json') {
        fetchOptions.body = JSON.stringify(fetchOptions.body);
      }
    }

    const response = await fetch(fetchUrl, fetchOptions);

    let isError = false;

    if (response.status > 500) {
      console.warn('callFetch',{
        content: '500',
        key: loaderKey,
      });
      isError = true;
    } else if (response.status === 404) {
      console.warn('callFetch',{
        content: '404',
        key: loaderKey,
      });
      isError = true;
    } else if (response.status === 403) {
      console.warn('callFetch',{
        content: '403',
        key: loaderKey,
      });
      isError = true;
    } else if (response.status === 401) {
      console.warn('callFetch',{
        content: '401',
        key: loaderKey,
      });
      isError = true;
    } else if (response.status < 200 || response.status >= 300) {
      console.warn('callFetch',{
        content: response.status,
        key: loaderKey,
      });
      isError = true;
    }

    const contentType = response.headers.get('content-type');
    let responseBody = false;

    if (contentType && contentType.includes('application/json')) {
      responseBody = await response.clone()
        .json();
    } else if (contentType && contentType.includes('text/html')) {
      responseBody = await response.clone()
        .text();
    }

    if (isError) {
      return {
        success: false,
        status: response.status,
        result: responseBody || false,
      };
    }
    // console.debug('callFetch',{
    //   content: 'Success',
    //   key: loaderKey,
    // });
    return {
      success: true,
      status: response.status,
      result: responseBody,
    };
  } catch (e) {
    console.warn('callFetch',{
      content: 'Fetch error',
      key: loaderKey,
    });
    // eslint-disable-next-line no-console
    console.warn(e);
  }
  console.warn('callFetch',{
    content: 'callFetch error',
    key: loaderKey,
  });
  return {
    success: false,
    status: false,
    result: false,
  };
};

export const apiFetch = (url, options) => {
  // console.debug("ApiFetch", sysConfig.PUBLIC_URL);
  return callFetch(
    urlJoin(sysConfig.PUBLIC_URL, url),
    options,
)
};

export default {
  apiFetch,
  callFetch,
};
